// General
$black: #000;
$white: #fff;
$white-54: rgb(255 255 255 / 54%);

// Home
$light-gray: #ddd;
$gray: #999;
$dark-gray: #666;
$light-black: #333;
$home-background: #ebebeb;
$timeline: #dcdcdc;
$font-small: 14px;
$section-separator: 15px;

// Create a space of 2px between footer and site-shopping-info
$small-home-padding: 64px 0 2px 0;
$home-padding: 96px 0 2px 0;

// Links
$link-hover: #1259c3;

// Item
$item-info-color: rgb(51 51 51);
$item-info-background: rgb(255 255 255 / 70%);
$item-min-height: 314px;
$item-max-height: 360px;
$item-height-mobile: 230px;
$font-size-mid: 20px;
$item-shadow: 32px;
$item-width: 224px;

// Transitions
$transition-duration: 0.1s;
$transition-timing-function: ease-out;
$transition-visibility-delay: 0.1s;

// Carousel
$image-border-radius: 4px;
$sub-title: 20px;
$secondary-button: #e3eefc;
$secondary-button-hover: #d9e8fc;

//screenSize
$screen-mobile-s: 360px;
$screen-mobile-m: 414px;
$screen-desktop-xs: 768px;
$screen-desktop-s: 1024px;
$screen-desktop-m: 1280px;
$screen-desktop-l: 1366px;
$screen-desktop-xl: 1920px;
$screen-desktop-xxl: 3000px;
