.card-with-image-price {
  border-radius: (0.75 * $mdu);
  color: $andes-black;
  height: (13.75 * $mdu);

  &.dark {
    background-color: $light-black;
    color: $andes-white;

    .card-with-image-price__text-content {
      color: $andes-white;
    }
  }

  &.light {
    background-color: $andes-white;
    color: $andes-black;

    .card-with-image-price__text-content {
      color: $andes-black;
    }
  }

  .card-with-image-price__url {
    display: flex !important;
    width: 100% !important;
  }

  .card-with-image-price__image-wrapper {
    align-items: center;
    background-color: $andes-white;
    border-radius: (0.75 * $mdu) 0 0 (0.75 * $mdu);
    display: flex;
    height: (13.75 * $mdu);
    justify-content: center;
    width: (17.5 * $mdu);

    .card-with-image-price__img {
      height: auto;
      max-height: (13.75 * $mdu);
      max-width: (17.5 * $mdu);
      width: auto;
    }
  }

  .card-with-image-price__text-content {
    color: $andes-black;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    .card-with-image-price__label {
      font-size: $font-size-12;
      line-height: (1.75 * $mdu);
      margin-bottom: $mdu;
      text-transform: uppercase;
    }

    .ui-item__price .price-tag-cents {
      position: relative;
      top: -1px;
    }
  }
}
