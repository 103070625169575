@import 'core';
@import '../../commons/hover';
@import '../../commons/variables';

.section-category-item__header {
  column-gap: rem($andes-spacing-24);
  grid-template-columns: minmax(50%, 1fr) fit-content(50%);
  grid-template-rows: minmax(auto, 95%) minmax(auto, 1fr);
  padding: rem($andes-spacing-16) rem($andes-spacing-20);
}

.section-category-item__container,
.section-category-item__image-container {
  border-radius: rem(12px);
}

.section-category-item__image,
.section-category-item__header {
  border-radius: rem(12px);
}

.section-category-item__title {
  align-self: center;
  font-size: rem($font-size-20);
  line-height: $line-height-s;
}

.section-category-item__price-container {
  font-size: rem($font-size-14);
  line-height: $line-height-s;
}

.section-category-item__button-container {
  align-items: center;
  display: flex;
  grid-column: 2/3;
  grid-row: 1/3;
  justify-content: flex-end;
  overflow: hidden;
}

.section-category-item__button {
  background: $andes-white;
  border-radius: rem($border-radius-4);
  border: rem(1px) solid $andes-white;
  color: $andes-gray-900;
  cursor: pointer;
  font-size: rem($font-size-16);
  font-weight: $font-weight-regular;
  line-height: $line-height-s;
  max-height: 92%;
  overflow: hidden;
  padding: rem($mshops-size-10);
  text-overflow: ellipsis;
  white-space: nowrap;

  &:focus,
  &:active,
  &:hover {
    background: $andes-white;
    border: rem(1px) solid $andes-white;
  }
}

.section-category-item__price--hidden .section-category-item__title {
  align-self: center;
}

.section-category-item__title--hidden.section-category-item__price--hidden.section-category-item__button--hidden {
  display: none;
}
